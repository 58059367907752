import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { IClientDetails } from "../../interfaces"
import ClientsProgress from "./ClientsProgress"


const useStyles = makeStyles({
    cell: {
        padding: 0,
        width: '20%'
    },
    detailsCell: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '35px',
        padding: 0,
        width: '12rem',
    },
})

interface IProps {
    client: IClientDetails
}

const TableRowDetails = ({ client }: IProps) => {
    const classes = useStyles();

    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell align="center">Progress status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cell} colSpan={1}>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <ClientsProgress client={client} />
                    </TableCell>
                    <TableCell className={classes.cell}></TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default TableRowDetails
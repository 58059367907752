import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import { ADMIN_API_URL } from "../config"
import { getOktaToken } from "../utils";
import axios from "axios";
import { CircularProgress } from "@material-ui/core"
import { Email } from "@material-ui/icons"
import { ActionAlert } from ".";
import { trackEvent, trackException, trackStackTrace } from "../utils/logger";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { McK_ELECTRIC_BLUE } from "../styles/colors";
import { updateClientProgress } from "../redux/reducers/clients";
import { IClientDetails } from "../interfaces";


interface IProps {
    client: IClientDetails
}

interface State {
    message: string
    isOpen: boolean
    isLoading: boolean
    isError: boolean
}

const useStyles = makeStyles({
    button: {
        width: '110px',
        padding: '1px',
    },
    primaryBtn: {
        backgroundColor: McK_ELECTRIC_BLUE,
    }
})

const EmailActionButton = ({ client }: IProps) => {
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const { admin } = useAppSelector(state => state)
    const [values, setValues] = useState<State>({
        message: '',
        isOpen: false,
        isError: false,
        isLoading: false,
    })
    

    const renderButton = () => {
        if (values.isLoading) {
            return <CircularProgress />
        }

        // send followup email if first email's been already sent
        if (client.isEmailSent) {
            return (
                <Tooltip title="Follow-up email">
                    <Button
                        variant="outlined"
                        size="small"
                        endIcon={<Email>followup</Email>}
                        className={classes.button}
                        onClick={sendEmail}
                    >
                        Follow-up
                    </Button>
                </Tooltip>
            )
        }

        return (
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={`${classes.button} ${classes.primaryBtn}`}
                endIcon={<Email>send</Email>}
                onClick={sendEmail}
            >
                Send
            </Button>
        )
    }

    function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        setValues({ ...values, isOpen: false })
    }

    // if error happened change icons and components accordingly
    const handleErrorCase = (message: string) => {
        setValues({ ...values, isLoading: false, isError: true, isOpen: true, message })
    }
    // if all good change icons and components accordingly
    const handleSuccessfulCase = (message: string) => {
        setValues({ ...values, isLoading: false, isError: false, message, isOpen: true })
    }
    
    const sendEmail = async () => {
        // set is loading true
        setValues({ ...values, isLoading: true })
        const tokenObj = getOktaToken()
       
        const token = tokenObj['accessToken'].accessToken;

        // if missing access token show error
        if (!token) {
            trackEvent('missing required okta access token', { adminEmail: admin.info?.email })
            alert(new Error('missing required access token', token))
        }

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            trackStackTrace(
                'send email by admin started',
                {
                    adminEmail: admin.info?.email,
                    clientID: client.id,
                    clientEmail: client.recipientWorkmail,
                }
            )
            // change API url to followup if a client's already has received first email
            let apiURL: string = `${ADMIN_API_URL}/clients/${client.id}/email`
            if (client.isEmailSent) {
                apiURL = `${ADMIN_API_URL}/clients/${client.id}/email?type=followup`
            }

            const bodyData = {
                recipient_workmail: client.recipientWorkmail,
                internal_cfs_upn: client.internalCfsUpn,
            }
            const resp = await axios.put(apiURL, bodyData, axiosConfig)
            trackStackTrace(
                'send email by admin finished',
                {
                    adminEmail: admin.info?.email,
                    clientEmail: client.recipientWorkmail,
                }
            )
            // update isEmailSent to true
            dispatch(updateClientProgress(client.id, true))
            handleSuccessfulCase(resp.data["message"])
        } catch (err: any) {
            trackException(new Error(err.response?.data.message || err.message))
            handleErrorCase(err.response?.data.message || err.message)
        }
    }

    return (
        <>
            {renderButton()}
            <ActionAlert
                isError={values.isError}
                isOpen={values.isOpen}
                message={values.message}
                onClose={handleClose}
            />
        </>
    )
}

export default EmailActionButton
import {
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Box,
    Typography,
    Tooltip,
    Checkbox,
    CircularProgress,
} from "@material-ui/core";
import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    CheckCircleOutline,
    HighlightOffOutlined,
    ErrorOutline
} from "@material-ui/icons"
import { ROW_HEIGHT } from "./ClientsTable";
import { useAppSelector } from "../../redux/hooks";
import EmailActionButton from "../EmailActionButton"
import { useState, Fragment, useEffect, ChangeEvent } from "react"
import ClientsProgress from "./ClientsProgress"
import { IClientDetails, IClientShortDetails } from "../../interfaces"
import TableRowDetails from "./TableRowDetails"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

// interface of props of the component
interface IProps {
    row: IClientDetails
    id: string
    rowIndex: number
    isItemSelected: boolean
    labelId: string
    handleSelectClick: (event: ChangeEvent<unknown>, client: IClientShortDetails) => void
}

// interface of state of the component
interface State {
    allAccountStatus: boolean
    errorMessage: string
    isError: boolean
    isLoading: boolean
    openCollapse: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: `${ROW_HEIGHT}px`,
        },
        detailsCell: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: '30px',
        },
        accountLogo: {
            width: '100%',
            height: '100%'
        }
    }),
);

const getBackgroundColor = (index: number): string => {
    if (index % 2 === 0) {
        return "#EAF6FC"
    }
    return ""
}



const Row = (props: IProps) => {
    const classes = useStyles();
    const [values, setValues] = useState<State>({
        allAccountStatus: false,
        isError: false,
        errorMessage: '',
        isLoading: false,
        openCollapse: false
    })
    const { row, rowIndex, isItemSelected, labelId, handleSelectClick } = props;
    
    return (
        <Fragment>
            <TableRow
                className={classes.root}
                style={{ backgroundColor: getBackgroundColor(rowIndex) }}
                aria-checked={isItemSelected}
                selected={isItemSelected}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setValues({ ...values, openCollapse: !values.openCollapse })}>
                        {values.openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">{row.internalCfsUpn}</TableCell>
                <TableCell align="left">{row.recipientWorkmail}</TableCell>
                <TableCell align="center">
                    <EmailActionButton client={row} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={values.openCollapse} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <TableRowDetails client={row} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}



export default Row